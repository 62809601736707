import React from "react";
import DOMPurify from "dompurify";

/**
 * Sanitizes/cleans the given HTML string using consistent rules for CR. Prefer using this over DOMPurify directly unless you specifically need to change the allowed tags.
 *
 * @param htmlString Potentially unsafe HTML string (e.g., one entered by a user in a form).
 */
export const cleanHtml = (htmlString, config = {}) => DOMPurify.sanitize(htmlString, config); // for now, using their default set
export const cleanHtmlForLabels = (htmlString, config = {}) => cleanHtml(htmlString, {USE_PROFILES: {html: false }}).replace(/&lt;/g, "<").replace(/&gt;/g, ">");

export const htmlEscape = str =>
  str
    .replace(/&/g, "&amp;")
    .replace(/"/g, "&quot;")
    .replace(/'/g, "&#39;")
    .replace(/</g, "&lt;")
    .replace(/>/g, "&gt;");

export const breakTemplating = str => str.replace(/{{/g, "{<span></span>{");

export const adjustGridDisplay = selector => {
  if (!$(selector).find(".module-grid").length) {
    return;
  }
  $(selector)
    .find(".module-grid")
    .css(
      "padding-top",
      $(selector)
        .find(".module-toolbar")
        .height() +
        30 +
        "px"
    );
  $(selector)
    .find(".module-grid")
    .css(
      "height",
      $(window).height() -
        $(selector)
          .find(".module-grid")
          .offset().top +
        "px"
    );
};

export const adjustDefaultGridDisplay = () => adjustGridDisplay("body");

/**
 * Convenience method to prevent default and stop propagation and then call thenCall.
 * @param evt - React event raised from element click.
 * @param thenCall - Func to call after suppressing default behaviors.
 */
export const handleClick = (
  evt: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement | HTMLDivElement | HTMLSpanElement>,
  thenCall: () => void
) => {
  evt.preventDefault();
  evt.stopPropagation();
  return thenCall();
};
